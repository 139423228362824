import Header from "../components/header2";

export default function PrivacyPolicy() {
    return (
        <>
            <div className="flex flex-col items-stretch bg-gray-100 min-h-screen">
                <Header />
                
                {/* Main Container */}
                <div className="bg-white self-center flex flex-col w-full max-w-[720px] 
                                 py-8 sm:px-6 sm:py-12 mt-6 mb-6">
                    
                    {/* Heading and Logo Section */}
                    <div className="flex flex-col items-center w-full mb-8">
                        <img
                            loading="lazy"
                            src="/aosError.png"
                            className="h-auto w-[150px] max-w-full object-contain mb-4"
                            alt="AOS Logo"
                        />
                        <h1 className="text-blue-800 text-center text-2xl sm:text-3xl font-bold leading-tight">
                            PRIVACY POLICY
                        </h1>
                    </div>
                    
                    {/* Privacy Policy Content */}
                    <div className="text-gray-800 text-base leading-relaxed space-y-6 max-w-[720px] p-4">
                        <p>
                            <strong>Effective Date:</strong> January 1, 2025
                        </p>
                        
                        <p>
                            This Privacy Policy (“Policy”) describes how <strong>AOS</strong> (“we,” “us,” or “our”) 
                            collects, uses, and discloses personal information that we obtain about visitors to 
                            our website (“Site”). By using our Site, you agree to the collection, use, and 
                            disclosure of your information in accordance with this Policy.
                        </p>

                        <h2 className="text-lg sm:text-xl font-bold mt-4">1. Information We Collect</h2>
                        <ul className="list-disc list-inside pl-4">
                            <li>
                                <strong>Personal Information:</strong> Such as your name, email address, 
                                postal address, and phone number when you submit forms or interact with our services.
                            </li>
                            <li>
                                <strong>Usage Information:</strong> Information about how you use our Site, 
                                including your IP address, browser type, and device information, as well as data 
                                collected through cookies and other tracking technologies.
                            </li>
                            <li>
                                <strong>Communications:</strong> The content of messages, queries, or feedback 
                                you send to us.
                            </li>
                        </ul>

                        <h2 className="text-lg sm:text-xl font-bold mt-4">2. How We Use Your Information</h2>
                        <ul className="list-disc list-inside pl-4">
                            <li>Provide, maintain, and improve our Site and services.</li>
                            <li>Respond to your questions, comments, or requests.</li>
                            <li>Communicate with you about updates, promotions, or news related to our Site or services.</li>
                            <li>Analyze usage and trends to better understand how users interact with our Site.</li>
                            <li>Detect, investigate, and help prevent security incidents and other malicious activities.</li>
                        </ul>

                        <h2 className="text-lg sm:text-xl font-bold mt-4">3. Cookies and Similar Technologies</h2>
                        <p>
                            We use cookies and similar technologies to recognize you when you visit our Site, 
                            remember your preferences, and personalize your experience. You can control cookies 
                            through your browser settings. However, if you choose to disable cookies, some 
                            features of our Site may not function properly.
                        </p>

                        <h2 className="text-lg sm:text-xl font-bold mt-4">4. How We Share Your Information</h2>
                        <ul className="list-disc list-inside pl-4">
                            <li>
                                <strong>Service Providers:</strong> Third parties who perform services on our 
                                behalf, such as hosting or analytics, under contractual obligations of confidentiality.
                            </li>
                            <li>
                                <strong>Compliance and Legal Obligations:</strong> We may disclose information if 
                                required by law or in response to valid requests by public authorities.
                            </li>
                            <li>
                                <strong>Business Transfers:</strong> In connection with a merger, acquisition, 
                                reorganization, or sale of assets, your information may be transferred as part 
                                of the transaction.
                            </li>
                        </ul>

                        <h2 className="text-lg sm:text-xl font-bold mt-4">5. Your Choices</h2>
                        <ul className="list-disc list-inside pl-4">
                            <li>Access and request correction or deletion of your personal information.</li>
                            <li>Opt out of receiving marketing communications from us.</li>
                            <li>Disable cookies through your browser settings.</li>
                        </ul>

                        <h2 className="text-lg sm:text-xl font-bold mt-4">6. Data Security</h2>
                        <p>
                            We use reasonable administrative, technical, and physical safeguards to protect 
                            your personal information. However, no method of transmission over the internet or 
                            electronic storage is 100% secure, and we cannot guarantee absolute security.
                        </p>

                        <h2 className="text-lg sm:text-xl font-bold mt-4">7. Children’s Privacy</h2>
                        <p>
                            Our Site is not directed at children under the age of 13 (or the relevant age of 
                            majority in your jurisdiction), and we do not knowingly collect personal information 
                            from children. If we discover that a child has provided us with personal information, 
                            we will delete such information from our systems.
                        </p>

                        <h2 className="text-lg sm:text-xl font-bold mt-4">8. Changes to This Policy</h2>
                        <p>
                            We may update this Policy from time to time. If we make changes, we will revise 
                            the “Effective Date” at the top of this Policy and post the updated Policy on the Site.
                        </p>

                        <h2 className="text-lg sm:text-xl font-bold mt-4">9. Contact Us</h2>
                        <p>
                            If you have any questions or concerns regarding this Policy, or if you would like 
                            to exercise your rights, please contact us at: 
                        </p>
                        <p>
                            <strong>Email:</strong>  youssef@aos.fit<br/>
                            <strong>Address:</strong> Gym facility in Sheikh Zayed City, Egypt

                        </p>
                    </div>
                </div>
                
                {/* Footer */}
                <div className="text-neutral-800 text-center text-sm sm:text-base leading-6 py-4">
                    Copyright - AOS
                </div>
            </div>
            <style jsx>{`
                html {
                    background-color: #F1F4F8;
                }
            `}</style>
        </>
    );
}