import React from 'react';

const CountdownTimer = ({ days = 0, hours = 0, minutes = 0, spotsLeft = 20 }) => {
  const padNumber = (num) => String(num).padStart(2, '0');

  return (
    <div className="countdown-container">
      <h2 className="countdown-header">Next program starts in</h2>
      
      <div className="timer-display">
        <div className="time-section">
          <span className="time-value">{padNumber(days)}</span>
          <span className="time-label">DAYS</span>
        </div>
        
        <span className="time-separator">:</span>
        
        <div className="time-section">
          <span className="time-value">{padNumber(hours)}</span>
          <span className="time-label">HOURS</span>
        </div>
        
        <span className="time-separator">:</span>
        
        <div className="time-section">
          <span className="time-value">{padNumber(minutes)}</span>
          <span className="time-label">MINUTES</span>
        </div>
      </div>

      <div className="spots-container">
        <span className="spots-text">ONLY</span>
        <span className="spots-number">{spotsLeft} SPOTS</span>
        <span className="spots-text">AVAILABLE !</span>
      </div>

      <style jsx>{`
        .countdown-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1rem;
          m
          width: 100%;
          max-width: 600px;
          margin: 0 auto;
        }

        .countdown-header {
          color: rgb(255, 69,0);
          font-size: 2rem;
          font-weight: bold;
          margin: 0;
          text-align: center;
        }

        .timer-display {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          justify-content: center;
        }

        .time-section {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.5rem;
        }

        .time-value {
          font-size: 2rem;
          font-weight: bold;
          color: rgb(255, 69,0);
        }

        .time-label {
          font-size: 1rem;
          color: rgb(255, 69,0);
          font-weight: bold;
        }

        .time-separator {
          font-size: 2rem;
          font-weight: bold;
          color: rgb(255, 69,0);
          margin: 0 0.5rem;
          line-height: 1;
          margin-bottom: 1.5rem;
        }

        .spots-container {
          display: flex;
          align-items: center;
          gap: 0.75rem;
        }

        .spots-text {
          font-size: 1.5rem;
          font-weight: bold;
        }

        .spots-number {
          font-size: 1.5rem;
          font-weight: bold;
          color: rgb(255, 69,0);
        }

        .book-button {
          background-color: rgb(255, 69,0);
          color: white;
          font-size: 1.5rem;
          font-weight: bold;
          padding: 1rem 2rem;
          border: none;
          border-radius: 8px;
          cursor: pointer;
          width: 100%;
          max-width: 400px;
          transition: background-color 0.2s;
        }

        .book-button:hover {
          background-color: rgb(220, 0, 0);
        }

        @media (max-width: 768px) {
          .countdown-header {
            font-size: 1.5rem;
          }

          .time-value {
            font-size: 3rem;
          }

          .time-separator {
            font-size: 3rem;
          }

          .spots-text {
            font-size: 1.5rem;
          }

          .spots-number {
            font-size: 1rem;
          }

          .book-button {
            font-size: 1.25rem;
            padding: 0.75rem 1.5rem;
          }
        }
      `}</style>
    </div>
  );
};

export default CountdownTimer;