import React from 'react';
import Form from '../form';
import { useState, useEffect } from 'react';
import CountdownTimer from './CountdownTimer';
const CustomButton = ({ onClick, buttonString, percent }) => (
  <button  onClick={onClick}  className="hicking-cta-button">
  LEARN MORE & BOOK YOUR SPOT
</button>
);
const RockClimbingChallenge = ({image1, image2, image3, wholeDate, spotsLeft}) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [wholeDates, setWholeDates] = useState(0);
  useEffect(() => {
    console.log(wholeDate, 'whole date33333333')
    const currentDate = new Date();
    const differenceInMilliseconds = wholeDate - currentDate;
    let day = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    let hour = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minute = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    day = Math.max(day, 0);
    hour = Math.max(hour, 0);
    minute = Math.max(minute, 0);

    console.log('rockcccccc',day, hour, minute)
    setDays(day)
    setHours(hour)
    setMinutes(minute)
    setWholeDates(wholeDate)
  }, [wholeDate]);


  useEffect(() => {
    const timerInterval = setInterval(() => {
      setMinutes(prevMinutes => {
        if (prevMinutes > 0) {
          return prevMinutes - 1;
        } else {
          setHours(prevHours => {
            if (prevHours > 0) {
              setMinutes(59);
              return prevHours - 1;
            } else {
              setDays(prevDays => {
                if (prevDays > 0) {
                  setHours(23);
                  setMinutes(59);
                  return prevDays - 1;
                } else {
                  clearInterval(timerInterval);
                  return 0;
                }
              });
            }
          });
        }
      });
    }, 60000);

    return () => clearInterval(timerInterval);
  }, [days, hours, minutes]);

  return (
    <>
<div className="rock-climbing-card">
  <br/>
<h2 className="rock-climbing-title1">
          OUR 30 DAYS HIKING<br />
          CHALLENGE GOT YOU COVERED
        </h2>
        <br/>
        <br/>
        <p className="rock-climbing-description">
        A 30-day strength and conditioning program
        designed to prepare you for HIKING
        </p>

        <p className="rock-climbing-subtitle">Here's how it works:</p>


{/* Train at AOS Section */}
<div className="rock-climbing-section">
  <img
    src={image1}
    alt="Training at AOS"
    className="rock-climbing-section-image"
  />
  <div className="rock-climbing-section-content">
    <h4 className="rock-climbing-section-title">Train at AOS</h4>
    <p className="rock-climbing-section-text">
      Build strength and stamina with exercises like squats, deadlifts,
      and HIIT workouts tailored to improve your climbing ability.
    </p>
  </div>
</div>

{/* Climb at Ascent Section */}
<div className="rock-climbing-section reverse">
<img
    src={image2}
    alt="Training at AOS"
    className="rock-climbing-section-image-placeholder"
  />


  <div className="rock-climbing-section-contentL">
    <h4 className="rock-climbing-section-title">
    Hiking Events
    </h4>
    <p className="rock-climbing-section-text">
    Once a week, We Do
different hikes for a realworld experience, improving
your skills with professional
instructors.<br />
    </p>
  </div>

</div>


        {/* The Grand Finale Section */}
        <div className="rock-climbing-section">
          <img
            src={image3}
            alt="Rock Climbing Trip"
           className="rock-climbing-section-image"
          />
              <div className="rock-climbing-section-content">
              <h4 className="rock-climbing-section-title">
              The Grand Finale: A 2-Day<br />
              Hiking Trip
            </h4>
            <p className="rock-climbing-section-text">
            At the end of the program, join
            your fellow AOSERS for a guided 
            Hiking trip in Sinai or Faiyoum,
            where you can put your new skills
            to the test!
            </p>
          </div>
        </div>

        <div className="rock-climbing-countdown">
  <CountdownTimer days={days} hours={hours} minutes={minutes} spotsLeft={spotsLeft}/>
  <br/>
        <div style={{ textAlign: 'center' }}>
          <Form type={'hiking'}  buttonComponent={(toggleModal) => <CustomButton buttonString="BOOK YOUR SPOT IN THIS CRAZY OFFER" onClick={toggleModal} />} />
        </div>
</div>

        <br/>
        <br/>
      </div>

      <style>{`
          .rock-climbing-card {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    background-color: white;
  }

  .rock-climbing-title1 {
    color:rgb(255,69,0);
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
    font-family: 'Futura XBlk BT', sans-serif;
    margin: -15px 0 0 0;
  }
  .rock-climbing-description {
    text-align: center;
    margin: 0 auto;
    width: 50%;
    max-width: 800px;
    color: black;
    font-family: 'Siro-Bold', sans-serif;
    line-height: 1.5;
    font-size: 1rem;
  }

  .rock-climbing-subtitle {
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  .rock-climbing-section {
    display: flex;
    margin-bottom: 2rem;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
  }

  .rock-climbing-section.reverse {
    flex-direction: row-reverse;
  }

  .rock-climbing-section-image,
  .rock-climbing-section-image-placeholder {
    width: 50%;
    max-width: 400px;
    height: 250px;
    border-radius: 0.5rem;
  }


  .rock-climbing-section-content,
  .rock-climbing-section-contentL {
    width: 50%;
    max-width: 400px;
  }

  .rock-climbing-section-content {
    margin-left: 3rem;
  }

  .rock-climbing-section-contentL {
    margin-right: 3rem;
    text-align: left;
  }

  .rock-climbing-section-title {
    font-weight: bold;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }

  .rock-climbing-section-text {
    color: #black;
    font-family: 'Siro-Bold', sans-serif;
    line-height: 1.5;
    font-size: 1rem;
    text-align: left;
  }

  .rock-climbing-countdown {
    font-family: 'Siro-Bold', sans-serif;
    color: black;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 20px 0;
  }

  .rock-climbing-countdown p {
    font-family: 'Calibri', sans-serif;
  }

  .rock-climbing-section-text {
    color: black;
    font-family: 'Siro-Bold', sans-serif;
    line-height: 1.5;
    font-size: 1rem;
  }

  .rock-climbing-timer {
    font-size: 2rem;
        text-align: center;
    color:rgb(255,69,0)
  }



  .rock-climbing-timer span {
    margin: 0 5px;
  }

  .hicking-cta-button {
    background-color: rgb(255,69,0);
    color: white;
    font-weight: bold;

    padding: 0.75rem 2rem;
    border-radius: 0.375rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .hicking-cta-button:hover {
    background-color: rgb(255,69,0);
  }

  @media (max-width: 768px) {
    .rock-climbing-section,
    .rock-climbing-section.reverse {
      flex-direction: column;
      align-items: center;
    }

    .rock-climbing-section-image,
    .rock-climbing-section-image-placeholder,
    .rock-climbing-section-content,
    .rock-climbing-section-contentL {
      width: 90%;
      max-width: 350px;
      margin: 0 0 1rem 0;
    }

    // .rock-climbing-section-image-placeholder {
    //   display: none;
    // }

    .rock-climbing-section-contentL {
      text-align: center;
    }

    .rock-climbing-section-title {
      text-align: center;
    }

    .rock-climbing-description {
    width: 90%;
    }
    .rock-climbing-title1 {
      text-align: center;
      font-size: 1.5rem;
      margin: 0;
    }
        .rock-climbing-section-text {
    text-align: center;
  }
    .hicking-cta-button {
    background-color: rgb(255,69,0);
    color: white;
    font-weight: bold;
    width: 90%;
    font-size: 1rem;
  }
  }
      `}</style>
    </>
  );
};

export default RockClimbingChallenge;
