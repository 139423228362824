import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  Redirect
} from "react-router-dom";
// import ReactPixel from "react-facebook-pixel";
import Fitness from "./pages/fitness";
import Circuit from "./pages/circuit";
import Home from "./pages/home";
import Gym from "./pages/gym";
import GWLP from "./pages/gwlp";
import ConceptHer from './pages/conceptHer';
import NotFoundPage from "./pages/notFound";
import Contact from "./pages/contact";
import Shred30 from "./pages/shred30";
import ThankYou from "./pages/thankYou";
import PrivacyPolicy from "./pages/privacy-policy";
import Ramadan from "./pages/ramadan"
import Pregnant from "./pages/pregnant";
import Revival from "./pages/revival";
import Youssef from './pages/youssef'
import RockClimbing  from "./pages/rockClimbing";
import Hiking from "./pages/hiking";
function App() {
	const action = useNavigationType();
	const location = useLocation();
	const pathname = location.pathname;

	useEffect(() => {
		if (action !== "POP") {
			window.scrollTo(0, 0);
		}
	}, [action, pathname]);

	// useEffect(() => {
	//   !function(f,b,e,v,n,t,s)
	//   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
	//   n.callMethod.apply(n,arguments):n.queue.push(arguments)};
	//   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
	//   n.queue=[];t=b.createElement(e);t.async=!0;
	//   t.src=v;s=b.getElementsByTagName(e)[0];
	//   s.parentNode.insertBefore(t,s)}(window, document,'script',
	//   'https://connect.facebook.net/en_US/fbevents.js');
	//   fbq('init', '381371453214684');
	//   fbq('track', 'PageView');
	//   // Initialize Facebook Pixel
	//   // const options = {
	//   //   autoConfig: true,
	//   //   debug: false,
	//   // };
	//   // ReactPixel.init('381371453214684', options);

	//   // // Track a page view when the component mounts
	//   // ReactPixel.pageView();
	// }, []);

	useEffect(() => {
		let title = "";
		let metaDescription = "";

		switch (pathname) {
			case "/":
				title = "";
				metaDescription = "";
				break;
		}

		if (title) {
			document.title = title;
		}

		if (metaDescription) {
			const metaDescriptionTag = document.querySelector(
				'head > meta[name="description"]'
			);
			if (metaDescriptionTag) {
				metaDescriptionTag.content = metaDescription;
			}
		}
	}, [pathname]);

	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/free-360-body-analysis" element={<Fitness />} />
			<Route path="/circuit-gym1" element={<Circuit />} />
			<Route path="/gym1" element={<Gym />} />
			<Route path="/cgwlp30" element={<GWLP />} />
			<Route path="/contact-us1" element={<Contact />} />
			<Route path="/concept-her1" element={<ConceptHer />} />
			<Route path="/shred-30" element={<Shred30 />} />
			<Route path="/revival" element={<Revival />} />
			<Route path="/pregnant-mama" element={<Pregnant />} />
			<Route path="/ramadan" element={<Ramadan />} />
			<Route path="/thank-you-page1669060964003" element={<ThankYou />} />
			<Route path="Youssef" element={<Youssef />} />
			<Route path="rockClimbing" element={<RockClimbing />} />
			<Route path="/hiking" element={<Hiking />} />
			<Route path="/privacy-policy" element={<PrivacyPolicy />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
}
export default App;
